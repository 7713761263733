import React, { FC, useEffect, useState } from 'react';
import {Box, Checkbox, CircularProgress, FormControlLabel, Typography} from '@mui/material';
import { PendingBillStatusEnum } from '../../../types/PendingBillStatus.enum';
import { PendingBillInterface } from '../../../types/PendingBill.interface';
import { StateEnum } from '../../../types/State.enum';
import { SortDirectionEnum } from '../../../types/SortDirection.enum';
import {useLazyQuery, useMutation} from '@apollo/client';
import {
  PendingBillsQueryInterface,
  PendingBillsQueryVariableInterface,
  ApprovePendingBillVariableInterface, PendingBillActions
} from './PendingBills.interface';
import {APPROVE_BILL, PENDING_BILLS_QUERY} from './PendingBills.graphql';
import GeneralModal from '../../../components/GeneralModal/GeneralModal';
import {BillForm, FormBillInterface} from '../BillForm/BillForm';
import {RejectForm} from '../RejectForm/RejectForm';
import {stateText} from "../../../const/stateText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import buttonTheme from "../../../theme/buttonTheme";
import {ThemeProvider} from "@mui/material/styles";
import {toast} from "react-toastify";
import {AdminTable} from "../../../components/AdminTable/AdminTable";

interface Columns {
  id: keyof PendingBillInterface;
  label: string;
}

export const columns: Columns[] = [
  {id: 'osCreatedAt', label: 'Created'},
  {id: 'likely',label: 'Likely'},
  {id: 'osBillId',label: 'Bill ID'},
  {id: 'osState', label: 'State'},
  {id: 'number', label: 'Number'},
  {id: 'osSession', label: 'Session'},
  {id: 'title',label: 'Title'},
  {id: 'osSubjects',label: 'Summary'},
];

const toastOptions = { position: toast.POSITION.TOP_CENTER };

export const PendingBills: FC = () => {
  // Query states
  const [state, setState] = useState<StateEnum>();
  const [keywords, setKeywords] = useState<string>('');
  const [status, setStatus] = useState<PendingBillStatusEnum[]>([PendingBillStatusEnum.NEW]);

  // Form states
  const [formShowRejected, setFormShowRejected] = useState<boolean>(false);

  // Pagination states
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('osCreatedAt');
  const [sortDirection, setSortDirection] = useState<SortDirectionEnum>(SortDirectionEnum.DESC);
  const [billAction, setBillAction] = useState<PendingBillActions>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedBill, setSelectedBill] = useState<FormBillInterface>();

  const onBillAction = (bill: PendingBillInterface, action: PendingBillActions) => {
    setSelectedBill({
      id: bill.id,
      title: bill.title,
      summary: bill.osSubjects,
      yearFiled: bill.yearFiled,
      externalId: bill.osBillId,
      state: bill.osState,
      number: bill.number,
      chamber: bill.osChamber,
      session: bill.osSession,
      sessionId: bill.osSessionId,
      status: bill.osStatus,
      // Set to "empty" because pending bills won't have these prior to approval.
      policyCategory: undefined,
      policySubcategory: [],
      spotPolicies: [],
      companionBills: [],
    });
    setBillAction(action);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedBill(undefined)
    setModalOpen(false);
    refetchBills();
  };

  const handleModalComplete = () => {
    setSelectedBill(undefined)
    setModalOpen(false);
    refetchBills();
  }

  const handleBillApprove = (data: FormBillInterface) => {
    approveBill({
      variables: {
        input: {
          id: data.id,
          title: data.title,
          description: data.summary,
          yearFiled: data.yearFiled,
          osBillId: data.externalId,
          state: data.state,
          number: data.number,
          chamber: data.chamber,
          session: data.session,
          status: data.status,
          policyCategory: data.policyCategory,
          policySubcategory: data.policySubcategory,
          spotPolicies: data.spotPolicies,
          companionBills: data.companionBills,
        }
      }
    })
  }

  const [getBills, { data: bills, loading, error, refetch: refetchBills }] = useLazyQuery<
    PendingBillsQueryInterface,
    PendingBillsQueryVariableInterface
  >(PENDING_BILLS_QUERY);

  const [approveBill] = useMutation<PendingBillInterface, ApprovePendingBillVariableInterface>(
    APPROVE_BILL,
    {
      onCompleted() {
        toast.success(`Bill approved.`, toastOptions);
        setSelectedBill(undefined)
        setModalOpen(false);
        refetchBills()
      },
      onError() {
        toast.error('Failed to approve bill. Try again.', toastOptions);
      }
    },
  );

  // Invoke on initial render, and when after state, keywords, and statuses change.
  useEffect(() => {
    getBills({
      variables: {
        pendingBills: {
          state: state ? [state] : undefined,
          keywords: keywords,
          status,
          pageNumber,
          pageSize,
          sortColumn,
          sortDirection,
        },
      },
    });
  }, [getBills, state, keywords, status, pageNumber, pageSize, sortColumn, sortDirection]);

  // Statuses on the query is an array.
  useEffect(() => {
    setStatus(formShowRejected
      ? [PendingBillStatusEnum.NEW, PendingBillStatusEnum.REJECTED]
      : [PendingBillStatusEnum.NEW]);
  }, [formShowRejected]);

  return (
    <div>
      <section>
        <form>
          <Box sx={{ mb: '1rem', display: 'flex', gap: '1rem' }}>
            <FormControl>
              <InputLabel
                shrink
                id="pending-bills-label">Select a state</InputLabel>
              <Select
                displayEmpty
                labelId="pending-bills-label"
                id="pending-bills-select"
                value={state ? state : ''}
                label="Select a state"
                onChange={(e) => {
                  setState(e.target.value as StateEnum ?? undefined);
                  setPageNumber(0);
                }}
              >
                <MenuItem value=""><span>All States</span></MenuItem>
                {Object.entries(stateText).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="pending-bills-keyword"
              label="Search by Keyword"
              onChange={(e) => {
                setKeywords(e.target.value);
                setPageNumber(0);
              }}/>

            <FormControlLabel
              control={<Checkbox
                checked={formShowRejected}
                onChange={(e) => {
                  setFormShowRejected(e.target.checked);
                  setPageNumber(0);
                }}/>}
              label="Include rejected bills" />
          </Box>
        </form>
        <Box>
          {loading && (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {error && <Typography align={"center"}>Failed to load bills.</Typography>}
          {bills && bills.pendingBillsCount === 0 && <Typography align={"center"}>No bills matched your search.</Typography>}
          {bills && bills.pendingBillsCount !== 0 && (
            <>
              <Typography>
                You are reviewing {Math.min(pageSize, bills.pendingBillsCount)} out of {bills.pendingBillsCount} bills
              </Typography>
              <AdminTable
                title={'New bills table'}
                columns={columns}
                data={bills.pendingBills}
                count={bills.pendingBillsCount}
                pageNumber={pageNumber}
                pageSize={pageSize}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onPageNumberChange={setPageNumber}
                onPageSizeChange={setPageSize}
                onSortChange={(c, d) => {
                  setSortColumn(c);
                  setSortDirection(d);
                  setPageNumber(0);
                }}
                actions={(bill) => (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <ThemeProvider theme={buttonTheme}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        disableFocusRipple
                        onClick={() => {
                          onBillAction(bill, PendingBillActions.APPROVE);
                        }}
                      >Approve</Button>
                      <Button
                        variant="outlined"
                        color="error"
                        disableElevation
                        disableFocusRipple
                        onClick={() => {
                          onBillAction(bill, PendingBillActions.REJECT);
                        }}
                        disabled={bill.status === PendingBillStatusEnum.REJECTED}
                      >{bill.status === PendingBillStatusEnum.REJECTED ? 'Rejected' : 'Reject'}</Button>
                    </ThemeProvider>
                  </Box>
                )}
              />
            </>
          )}
        </Box>
      </section>
      {selectedBill && billAction && (
        <GeneralModal onClose={handleModalClose} modalOpen={modalOpen}>
          {billAction === PendingBillActions.APPROVE && (
            <BillForm
              formTitle={"Add Bill to Tracker"}
              onSubmit={handleBillApprove}
              onCancel={handleModalClose}
              bill={selectedBill} />
          )}
          {billAction === PendingBillActions.REJECT && (
            <RejectForm
              onComplete={handleModalComplete}
              onCancel={handleModalClose}
              billId={selectedBill.id} />
          )}
        </GeneralModal>
      )}
    </div>
  );
};
