import { useMutation } from '@apollo/client';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { PendingBillInterface } from '../../../types/PendingBill.interface';
import { RejectPendingBillVariableInterface } from './RejectForm.interface';
import { REJECT_PENDING_BILL } from './RejectForm.graphql';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {ThemeProvider} from "@mui/material/styles";
import buttonTheme from "../../../theme/buttonTheme";

interface RejectFormProps {
  billId: string,
  onComplete: () => void;
  onCancel: () => void;
}

const toastOptions = { position: toast.POSITION.TOP_CENTER };

export const RejectForm: FC<RejectFormProps> = ({ billId, onCancel, onComplete }) => {
  const [rejectPendingBill] = useMutation<PendingBillInterface, RejectPendingBillVariableInterface>(
    REJECT_PENDING_BILL,
    {
      onError() {
        toast.error('Bill failed to save. Try again', toastOptions);
      },
    },
  );

  const handleSubmit = () => {
    rejectPendingBill({
      variables: {
        id: billId,
      },
      onCompleted() {
        toast.success(`Bill Rejected`, toastOptions);
        onComplete();
      },
    });
  };

  return (
    <div>
      <h2>Reject Bill</h2>
      <p>Are you sure you want to reject this bill?</p>
      <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
        <ThemeProvider theme={buttonTheme}>
          <Button
            variant="contained"
            color="error"
            disableElevation
            disableFocusRipple
            onClick={onCancel}>No, Do Not Reject</Button>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            disableFocusRipple
            onClick={handleSubmit}>Yes, Reject</Button>
        </ThemeProvider>
      </Box>
    </div>
  );
};
